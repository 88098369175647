import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import FormInputDropdown from './FormInputDropdown';
import { getMnoList } from '../services/permissionUtil';
import { selectMnos, getMnos } from '../main/support/store/mnosSlice';

function FormMnoDropdown(props) {
  const { permissions } = useSelector(({ auth }) => auth.user);
  const { allmno, noPermissionFiltering, ...remainingProps } = props;

  const allMnoList = getMnoListNames(useSelector(selectMnos));

  const dispatch = useDispatch();

  function getMnoListNames(mnos) {
    return mnos
      .filter(({ status }) => ['testing', 'deployed'].includes(status))
      .map(({ mno }) => mno);
  }

  useEffect(() => {
    dispatch(getMnos());
  }, [dispatch]);
  return (
    <FormInputDropdown
      {...remainingProps}
      options={getMnoList(
        permissions,
        allMnoList,
        allmno === 'true',
        noPermissionFiltering
      ).map((mno) => ({
        label: _.upperCase(mno),
        value: mno,
      }))}
    />
  );
}

export default FormMnoDropdown;
