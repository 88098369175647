import FusePageCarded from '@fuse/core/FusePageCarded';
import withReducer from 'app/store/withReducer';
import { styled, useTheme } from '@mui/material/styles';
import CustomDataGrid from 'app/shared-components/CustomDataGrid';
import { useDispatch, useSelector } from 'react-redux';
import { forwardRef, useEffect, useState } from 'react';
import { Button, Chip, Snackbar, Tooltip } from '@mui/material';
import {
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
} from '@mui/x-data-grid-pro';
import { useLocation, useSearchParams } from 'react-router-dom';
import { InfoOutlined, Today, Update } from '@mui/icons-material';
import MuiAlert from '@mui/material/Alert';
import { showMessage } from 'app/store/fuse/messageSlice';
import HistoryIcon from '@mui/icons-material/History';
import { getOrders, selectOrders } from '../store/ordersSlice';
import reducer from '../store';
import OrdersAction from './OrdersAction';
import { getOrderStatus } from '../order/orderHelper';
import {
  getTargetedPercentage,
  getSendingStartDateTime,
  getSendingEndDateTime,
  getRejectionReason,
  getTargetedCount,
} from '../executionStatuses/executionStatusHelper';
import PageHeader from '../../../shared-components/PageHeader';
import ProgressBar from '../../../shared-components/ProgressBar';
import { getOrderStatusByName, OrderStatus } from '../../../services/constants';
import _ from '../../../../@lodash';
import {
  isSingleMno,
  isSingleBillingAccount,
  isSingleTemplate,
  getMnoList,
  isUserHasFullOrderAccess,
} from '../../../services/permissionUtil';
import { toMnoLocalTimeStr } from '../../../services/dateUtil';
import { openDialog } from '../../../store/fuse/dialogSlice';
import UserNotificationDialog from '../notifications/UserNotificationDialog';
import { SCHEDULE, SUBMIT } from '../../admin/permission/constants';
import { saveFilter } from '../../../store/customDataGridSlice';
import * as ordersConstants from './ordersConstants';
import getTemplateName, { getAccountName } from '../../../services/orderUtil';
import { getTemplates, selectTemplates } from '../../admin/store/templatesSlice';
import { selectAccounts, getAccounts } from '../../billing-admin/store/accountsSlice';
import filterUtil from '../../../services/filterUtil';
import {
  getLinkOperator,
  getNumberFilterOperators,
  getDateFilterOperators,
  getStringFilterOperators,
  getPermissionFilterOperators,
  getEntitiesByPermissions,
} from '../../../services/dataGridUtil';
import OrderPresetFilters from './components/OrderPresetFilters';

const Alert = forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Root = styled(FusePageCarded)(({ theme }) => ({
  '& .FusePageCarded-header': {
    minHeight: 72,
    height: 72,
    alignItems: 'center',
    [theme.breakpoints.up('sm')]: {
      minHeight: 136,
      height: 136,
    },
  },
  '& .FusePageCarded-content': {
    display: 'flex',
  },
  '& .FusePageCarded-contentCard': {
    overflow: 'hidden',
  },
}));

function filterByMnoResult(filterModel, mno) {
  const mnoFilter = filterModel.items.find((item) => item.columnField === 'mno');
  if (!mnoFilter) return true;
  if (mnoFilter.operatorValue === 'is') {
    return mno === mnoFilter.value;
  }
  if (mnoFilter.operatorValue === 'not') {
    return mno !== mnoFilter.value;
  }
  if (mnoFilter.operatorValue === 'isAnyOf') {
    return mnoFilter.value?.includes(mno);
  }
  return true;
}

const columns = (
  singleMno,
  singleAccount,
  singleTemplate,
  orders,
  templates,
  permissions,
  accounts,
  theme,
  filterModel
) => [
  {
    field: 'state',
    valueGetter: (params) => getOrderStatus(params.row).name,
    renderCell: (params) => {
      return (
        <Chip
          icon={
            getRejectionReason(params.row) && (
              <Tooltip title={getRejectionReason(params.row)}>
                <InfoOutlined style={{ color: 'white' }} />
              </Tooltip>
            )
          }
          label={_.startCase(_.lowerCase(getOrderStatus(params.row).name))}
          sx={{
            bgcolor: theme.palette.order_status[_.snakeCase(getOrderStatus(params.row).name)],
            color: theme.palette.common.white,
          }}
          size="small"
        />
      );
    },
    sortComparator: (v1, v2) => getOrderStatusByName(v1).order - getOrderStatusByName(v2).order,
    headerName: 'Status',
    type: 'singleSelect',
    valueOptions: [...new Set(Object.keys(OrderStatus))],
    flex: 0.8,
  },
  {
    field: 'id',
    headerName: 'Order ID',
    flex: 0.75,
    hide: true,
    filterOperators: getStringFilterOperators(),
  },
  {
    field: 'mno',
    headerName: 'MNO',
    type: 'singleSelect',
    valueFormatter: (params) => {
      return _.upperCase(params.value);
    },
    valueOptions: [...new Set(getMnoList(permissions, false, false))],
    filterOperators: getPermissionFilterOperators(permissions, 'mno'),
    flex: 0.6,
    hide: singleMno,
  },
  {
    field: 'billingAccountId',
    headerName: 'Billing Account',
    type: 'singleSelect',
    valueFormatter: (params) => getAccountName(accounts, params.value),
    valueOptions: [
      ...new Map(
        getEntitiesByPermissions(permissions, accounts, 'billingAccount').map((account) => [
          account.id,
          {
            value: account.id,
            label: `${account.name} (${account.mno})`,
            mno: account.mno,
          },
        ])
      ).values(),
    ]
      .filter((acc) => filterByMnoResult(filterModel, acc.mno))
      .sort((a, b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase())),
    filterOperators: getPermissionFilterOperators(permissions, 'billingAccount'),
    flex: 1,
    filterable: true,
    hide: singleAccount,
  },
  {
    field: 'campaignName',
    headerName: 'Campaign Name',
    valueGetter: (params) => params.row?.name || params.row?.definition?.name,
    filterOperators: getStringFilterOperators(),
    flex: 1,
    filterable: true,
  },
  {
    field: 'product',
    headerName: 'Product',
    valueGetter: (params) => params.row?.product,
    filterOperators: getPermissionFilterOperators(
      permissions,
      filterUtil.FILTER_MAP.product.permissionName
    ),
    valueOptions: filterUtil.getFilteredProductsByPermission(permissions),
    flex: 1,
    filterable: true,
    hide: true,
  },
  {
    field: 'templateId',
    headerName: 'Template Name',
    valueFormatter: (params) => getTemplateName(templates, params.value),
    type: 'singleSelect',
    valueOptions: [
      ...new Map(
        getEntitiesByPermissions(permissions, templates, 'campaignTemplate').map((template) => [
          template.id,
          {
            value: template.id,
            label: template.name,
            mno: template.mno,
          },
        ])
      ).values(),
    ]
      .filter((template) => filterByMnoResult(filterModel, template.mno))
      .sort((a, b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase())),
    filterOperators: getPermissionFilterOperators(permissions, 'campaignTemplate'),
    flex: 1,
    hide: singleTemplate,
  },
  {
    field: 'validFrom',
    headerName: 'Validity Start',
    type: 'date',
    valueGetter: (params) => `${params.row.validFrom} ${params.row.startTime || ''}`,
    filterOperators: getDateFilterOperators(),
    flex: 1,
  },
  {
    field: 'validTo',
    headerName: 'Validity End',
    type: 'date',
    flex: 1,
    valueGetter: (params) => `${params.row.validTo} ${params.row.endTime || ''}`,
    filterOperators: getDateFilterOperators(),
    hide: true,
  },
  {
    field: 'sendingStart',
    headerName: 'Sending Start',
    type: 'date',
    valueGetter: (params) => getSendingStartDateTime(params.row),
    valueFormatter: (params) => params.value || 'N/A',
    filterable: false,
    flex: 1.1,
  },
  {
    field: 'sendingEnd',
    headerName: 'Sending End',
    valueGetter: (params) => getSendingEndDateTime(params.row),
    valueFormatter: (params) => params.value || 'N/A',
    type: 'date',
    filterable: false,
    flex: 1.1,
  },
  {
    field: 'targetRequested',
    headerName: 'Ordered',
    type: 'number',
    flex: 1,
    filterOperators: getNumberFilterOperators(),
  },
  {
    field: 'targetable',
    headerName: 'Targetable',
    type: 'number',
    valueGetter: (params) => params.row.targetable || 'N/A',
    flex: 1,
    filterOperators: getNumberFilterOperators(),
  },
  {
    field: 'targeted',
    headerName: 'Targeted',
    type: 'number',
    valueGetter: (params) => getTargetedCount(params.row),
    filterable: false,
    flex: 1,
  },
  {
    field: 'targetedPercentage',
    valueGetter: (params) => getTargetedPercentage(params.row),
    renderCell: (params) => {
      return <ProgressBar value={getTargetedPercentage(params.row)} />;
    },
    headerName: '% Targeted',
    headerAlign: 'center',
    filterable: false,
    flex: 1,
  },
  {
    field: 'createdAt',
    headerName: 'Created At',
    type: 'date',
    hide: true,
    valueGetter: (params) => toMnoLocalTimeStr(params.row.createdAt, params.row.mno),
    filterOperators: getDateFilterOperators(),
  },
  {
    field: 'updatedAt',
    headerName: 'Updated At',
    type: 'date',
    hide: true,
    valueGetter: (params) => toMnoLocalTimeStr(params.row.updatedAt, params.row.mno),
    filterOperators: getDateFilterOperators(),
  },
  {
    field: 'ownerId',
    headerName: 'Created By',
    valueGetter: (params) => params.row?.ownerId,
    filterOperators: getStringFilterOperators(),
    flex: 1,
    hide: true,
  },
  {
    field: 'actions',
    type: 'actions',
    headerName: 'Actions',
    flex: 1.5,
    renderCell: (cellValues) => {
      return (
        <OrdersAction
          cellValues={cellValues}
          hasExecutionStatus={
            cellValues.row.executionStatuses?.items?.filter((status) => !status.rejected).length > 0
          }
        />
      );
    },
  },
];

function Orders(props) {
  const theme = useTheme();
  const { permissions } = useSelector(({ auth }) => auth.user);
  const location = useLocation();
  const { filter } = useSelector(({ dataGrid }) => dataGrid);

  const singleMno = isSingleMno(permissions);
  const singleAccount = isSingleBillingAccount(permissions);
  const singleTemplate = isSingleTemplate(permissions);
  const templates = useSelector(selectTemplates);

  const dispatch = useDispatch();
  const { loading, tableFilterIndex, nextToken, total, rowCount, tokenMap } = useSelector(
    ({ journey }) => journey.orders
  );

  const orders = useSelector(selectOrders);
  const accounts = useSelector(selectAccounts);
  const [searchParams, setSearchParams] = useSearchParams();
  const [dataGridKey, setDataGridKey] = useState(0);
  const [dataReady, setDataReady] = useState(false);
  const [actionRequiredOrderType, setActionRequiredOrderType] = useState(null);
  const [filterModel, setFilterModel] = useState({
    items: [],
  });

  const setPage = (pageNumber) => {
    searchParams.set('page', pageNumber);
    setSearchParams(searchParams, { replace: true });
  };

  const page = searchParams.get('page') ? parseInt(searchParams.get('page'), 10) : 0;

  const [isOpen, setOpen] = useState(false);

  const highlighted = searchParams.get('highlighted');

  function checkActionRequiredOrders() {
    if (!orders || orders.length === 0) {
      return;
    }

    const approvePermission = permissions.some((permission) => permission.permission === 'Approve');
    const submitPermission = permissions.some((permission) => permission.permission === 'Submit');

    if (
      approvePermission &&
      orders.some((order) => order.status === OrderStatus.WAITING_FOR_APPROVAL.name)
    ) {
      setOpen(true);
      setActionRequiredOrderType(OrderStatus.WAITING_FOR_APPROVAL.name);
    } else if (
      submitPermission &&
      orders.some((order) => order.status === OrderStatus.REJECTED.name)
    ) {
      setOpen(true);
      setActionRequiredOrderType(OrderStatus.REJECTED.name);
    }
  }

  const handlePageChange = (pageNumber, apiRef) => {
    if (!apiRef.api.windowRef || !apiRef.api.windowRef.current) return;
    if (nextToken && pageNumber > page) {
      setPage(pageNumber);
      dispatch(
        getOrders({
          filter: filterUtil.getModelForQuery(filterModel, accounts),
          nextToken,
          pageNumber,
        })
      );
    } else {
      setPage(pageNumber);
      dispatch(
        getOrders({
          filter: filterUtil.getModelForQuery(filterModel, accounts),
          pageNumber,
        })
      );
    }
  };

  useEffect(() => {
    dispatch(getAccounts());
  }, [dispatch]);



  const FilterButtonGroup = () => {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
        <GridToolbarExport
          csvOptions={{
            delimiter: ';',
            fileName: `Orders_${new Date().toISOString().split('T')[0]}`,
          }}
          printOptions={{ disableToolbarButton: true }}
        />
        <OrderPresetFilters handleFilterChange={handleFilterChange}/>
      </GridToolbarContainer>
    );
  };

  useEffect(() => {
    if (!tokenMap || !tokenMap[page]) {
      searchParams.delete('page');
      setSearchParams(searchParams);
    }
  }, [page, tokenMap, searchParams, setSearchParams]);

  useEffect(() => {
    if (dataReady) {
      if (filter.orders) {
        setFilterModel(filter.orders);
      } else {
        handleFilterChange(
          {
            items: filterUtil.convertPermissionsToMuiFilter(permissions, accounts, templates),
          },
          searchParams.get('keep')
        );
        window.history.replaceState({}, document.title);
      }
    }
  }, [dataReady]); // Only depends on the dataReady state

  useEffect(() => {
    // Check if all dependencies have data
    if (permissions.length > 0 && accounts.length > 0 && templates.length > 0 && filter) {
      setDataReady(true);
    }
  }, [permissions, accounts, templates, filter]);

  useEffect(() => {
    dispatch(getTemplates());
  }, [dispatch]);

  useEffect(() => {
    if (dataReady) {
      checkActionRequiredOrders();
    }
  }, [orders]); // Only depends on the dataReady state

  if (
    searchParams &&
    searchParams.get('notificationSettings') &&
    searchParams.get('notificationSettings') === 'true'
  ) {
    dispatch(
      openDialog({
        children: <UserNotificationDialog />,
      })
    );
  }

  function handleStateChange() {
    return (state) => {
      const visibleRows = state.filter.visibleRowsLookup;
      const visibleItems = [];
      if (
        state.filter.filterModel &&
        state.filter.filterModel.items &&
        state.filter.filterModel.items.length
      ) {
        for (const [id, value] of Object.entries(visibleRows)) {
          if (value === true) {
            visibleItems.push(id);
          }
        }
      }
    };
  }

  function validateProductFilterWithMno(model) {
    if (
      !model.items.some((item) => item.columnField === 'mno' && item.value) &&
      model.items.some((item) => item.columnField === 'product' && item.value) &&
      isUserHasFullOrderAccess(permissions)
    ) {
      dispatch(
        showMessage({ message: 'Please select an MNO to filter by product', variant: 'warning' })
      );
    }
  }

  function handleFilterChange(model, keepOrder) {
    validateProductFilterWithMno(model);
    if (!_.isEqual(model, filterModel) || !tokenMap[page]) {
      searchParams.delete('page');
      setSearchParams(searchParams);
      setFilterModel({ ...model });
    }
    if (model && model !== filter.orders) {
      const newModel = filterUtil.getModelForQuery({ ...model }, accounts);
      const filterOptions = { filter: newModel, pageNumber: page };

      if (keepOrder) {
        filterOptions.keepNewOrder = keepOrder;
      }

      dispatch(getOrders(filterOptions));
    }
  }

  function newOrderDisabled() {
    return !permissions.filter(
      (permission) => permission.permission === SCHEDULE || permission.permission === SUBMIT
    ).length;
  }

  function handleClose() {
    setOpen(false);
  }

  function setWaitingForApprovalFilter() {
    if (actionRequiredOrderType) {
      const model = {
        items: filterUtil.convertPermissionsToMuiFilter(permissions, accounts, templates),
      };
      model.items.push({
        columnField: 'state',
        value: actionRequiredOrderType,
        operatorValue: 'is',
        id: '60000',
      });
      handleFilterChange(model);
      handleClose();
    }
  }

  return (
    <Root
      header={
        <>
          <PageHeader
            iconName="list"
            disabled={newOrderDisabled()}
            title="Orders"
            buttonName="New Order"
            buttonLink="/orders/new"
          />
          <Snackbar
            open={isOpen}
            onClose={handleClose}
            sx={{ height: '25%' }}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          >
            <Alert
              severity="info"
              action={
                <Button onClick={setWaitingForApprovalFilter} color="primary" size="small">
                  Show
                </Button>
              }
            >
              There are Orders that require your action.
            </Alert>
          </Snackbar>
        </>
      }
      content={
        <CustomDataGrid
          key={dataGridKey}
          name={ordersConstants.DATA_GRID_NAME}
          defaultSorting={ordersConstants.DEFAULT_SORTING}
          rows={orders}
          columns={columns(
            singleMno,
            singleAccount,
            singleTemplate,
            orders,
            templates,
            permissions,
            accounts,
            theme,
            filterModel
          )}
          filterModel={filterModel}
          componentsProps={{
            filterPanel: {
              linkOperators: getLinkOperator(permissions),
            },
          }}
          density="compact"
          pageSize={50}
          rowsPerPageOptions={[50]}
          rowCount={total}
          paginationMode="server"
          onPageChange={handlePageChange}
          page={page}
          customToolbar={FilterButtonGroup}
          filterMode="server"
          loading={loading}
          onStateChange={handleStateChange()}
          onFilterChange={(model) => handleFilterChange(model)}
          hideFooterPagination={loading}
          getRowClassName={(params) => {
            if (highlighted && params.row.id === highlighted) {
              return 'bg-blue-100';
            }
            return '';
          }}
        />
      }
      innerScroll
    />
  );
}

export default withReducer('journey', reducer)(Orders);
