import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import FormInputDropdown from 'app/shared-components/FormInputDropdown';
import { convertMnoNamesToIDs, getMergedPermissionAttribute } from 'app/services/reportsUtil';
import Root from 'app/shared-components/Root';
import GridBigDataColumnRenderer from 'app/shared-components/GridBigDataColumnRenderer';
import CustomDataGrid from 'app/shared-components/CustomDataGrid';
import { ATTRIBUTE_NAME_QS_DASHBOARD_ID_PAYMENT_HISTORY } from 'app/services/constants';
import QuickSightFrame from 'app/shared-components/QuickSightFrame';
import { getAccountsOfUserWithPaymentFunctionality } from 'app/services/permissionUtil';
import PageHeader from '../../../shared-components/PageHeader';
import { getMnos } from '../store/reportsSlice';
import FuseLoading from '../../../../@fuse/core/FuseLoading';
import {
  getAccounts,
  selectAccountById,
  selectAccounts,
} from '../../billing-admin/store/accountsSlice';
import { getIncompletePayments } from '../store/paymentSlice';
import { SELECT_ALL_ITEM_VALUE } from '../../admin/permission/constants';
import { getUserSettings, selectUserSettingById } from '../../admin/store/userSettingsSlice';

const schema = yup.object().shape({
  billingAccountIdParameter: yup.string().required('Billing Account id required'),
});

const defaultValues = {
  billingAccountIdParameter: '',
};

function PaymentHistory() {
  const dispatch = useDispatch();
  const { permissions } = useSelector(({ auth }) => auth.user);
  const { loadingMnos, mnos } = useSelector(({ journey }) => journey.reports);
  const { incompletePayments, incompletePaymentsLoading } = useSelector(
    ({ journey }) => journey.payment
  );
  const mnosOfUser = getMergedPermissionAttribute(permissions, 'mno');
  const user = useSelector(({ auth }) => auth.user);
  const userSettings = useSelector((state) => selectUserSettingById(state, user.username));

  const isMnoOrResellerUser =
    user.userSettings.viewMNOBillingReport || user.userSettings.viewResellerBillingReport;

  const columns = [
    {
      field: 'createdAt',
      headerName: 'Payment Date',
      flex: 1,
      valueFormatter: (params) =>
        params.value ? new Date(params.value).toLocaleDateString('en-GB') : 'Not Found',
    },
    {
      field: 'responseMessage',
      headerName: 'Status',
      flex: 1,
    },
    {
      field: 'accountMno',
      headerName: 'MNO',
      flex: 1,
      valueGetter: () => selectedAccount?.mno,
    },
    { field: 'email', headerName: 'Username', flex: 1 },
    {
      field: 'transactionId',
      headerName: 'Transaction ID',
      flex: 1,
    },
    {
      field: 'amountRequested',
      headerName: 'Amount',
      flex: 1,
    },
    {
      field: 'fee',
      headerName: 'Payment Fee',
      flex: 1,
    },
    {
      field: 'updatedAt',
      headerName: 'Updated At',
      hide: true,
      flex: 1,
    },
    {
      field: 'fullJson',
      flex: 1,
      headerName: 'Full JSON',
      renderCell: (params) => (
        <GridBigDataColumnRenderer title="Full JSON" value={JSON.stringify(params.row)} />
      ),
      hide: true,
    },
    {
      field: 'channel',
      headerName: 'Channel',
      flex: 1,
      hide: true,
    },
  ];

  const { control, watch, setValue } = useForm({
    mode: 'onChange',
    defaultValues,
    resolver: yupResolver(schema),
  });

  const billingAccountIdParameter = watch('billingAccountIdParameter');

  const accounts = useSelector(selectAccounts);
  const selectedAccount = useSelector((state) =>
    selectAccountById(state, billingAccountIdParameter)
  );

  function getTags() {
    const billingAccountId = getMergedPermissionAttribute(permissions, 'billingAccount');

    return {
      cpBillAccId: billingAccountId.toString(),
      mno_ids: convertMnoNamesToIDs(mnos, mnosOfUser).toString(),
    };
  }

  useEffect(() => {
    if (!billingAccountIdParameter || !user) return;
    const structuredParam =
      billingAccountIdParameter === 'All'
        ? getAccountsOfUserWithPaymentFunctionality(accounts, permissions).map((acc) =>
            acc.id.toString()
          )
        : [billingAccountIdParameter.toString()];
    dispatch(getIncompletePayments({ billingAccountIdParameter: JSON.stringify(structuredParam) }));
  }, [dispatch, user, billingAccountIdParameter]);

  useEffect(() => {
    if (!accounts.length) dispatch(getAccounts());
    if (!mnos.length) dispatch(getMnos());
    if (!userSettings) dispatch(getUserSettings());
  }, [dispatch]);

  useEffect(() => {
    const accountsOfUser = getAccountsOfUserWithPaymentFunctionality(accounts, permissions);
    if (accountsOfUser && accountsOfUser.length) {
      setValue('billingAccountIdParameter', 'All');
    }
  }, [accounts, permissions]);

  function getAccountDropdownOptions() {
    const accountsOfUser = getAccountsOfUserWithPaymentFunctionality(accounts, permissions);
    const options = accountsOfUser?.map((account) => ({
      value: account.id,
      label: account.name === account.mno ? account.name : `${account.name} (${account.mno})`,
    }));
    return options && options.length
      ? [{ value: 'All', label: 'All' }, ...options]
      : [];
  }

  function getUrlParams() {
    return { billingAccountIdParameter };
  }

  function shouldIncompletePaymentsBeShown() {
    return (
      incompletePayments &&
      incompletePayments.length > 0 &&
      !isMnoOrResellerUser &&
      !loadingMnos &&
      billingAccountIdParameter
    );
  }

  return (
    <Root
      header={<PageHeader title="Payment History" noHeaderButton iconName="payments" />}
      content={
        <section className="p-24 w-full h-full flex flex-col">
          <header className="max-w-[400px]">
            <FormInputDropdown
              name="billingAccountIdParameter"
              label="Billing Account"
              control={control}
              mno={mnosOfUser}
              options={getAccountDropdownOptions()}
              required
            />
          </header>
          <div className="mt-36 flex flex-col gap-36 grow">
            {loadingMnos || !billingAccountIdParameter || incompletePaymentsLoading ? (
              <FuseLoading />
            ) : (
              shouldIncompletePaymentsBeShown() && (
                <>
                  <header className="font-bold text-lg">Pending and Failed Payments</header>
                  <div className="min-h-[300px]">
                    <CustomDataGrid
                      autoHeight
                      pageSize={5}
                      name="Incomplete Payments"
                      rows={incompletePayments ?? []}
                      columns={columns}
                    />
                  </div>
                </>
              )
            )}
            <>
              <header className="font-bold text-lg">Completed Payments</header>
              <QuickSightFrame
                dashboardIdAttributeName={ATTRIBUTE_NAME_QS_DASHBOARD_ID_PAYMENT_HISTORY}
                tags={getTags()}
                urlParams={getUrlParams()}
              />
            </>
          </div>
        </section>
      }
      innerScroll
    />
  );
}

export default PaymentHistory;
